@use "../../style/abstracts/variables/breakpoints";

.list {
  background-color: #fff;
  box-shadow:
    0 2px 1px -1px rgba(0, 0, 0, 0.2),
    0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 1px 3px 0 rgba(0, 0, 0, 0.12);
  &:not(.compact) {
    @media (min-width: breakpoints.$screen-sm) {
      display: flex;
      justify-content: space-evenly;
      flex-wrap: wrap;
      box-shadow: none;
      background: none;
    }
  }
  &.compact {
    box-shadow: none;
  }
}
