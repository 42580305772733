@use "../../style/abstracts/variables/breakpoints";

img {
  width: 100%;
  height: auto;
}

.header {
  position: relative;
  overflow: hidden;

  & h1 {
    font-size: 2.5em;
    margin: 0.25em 0;
  }

  & .backgroundsImage {
    position: absolute;
  }

  & .overlay {
    background-color: rgba(25, 25, 25, 0.75);
    display: flex;
    position: relative;
    padding: 16px 24px;

    & .logo {
      margin: auto;
      width: 200px;
    }
    @media (min-width: breakpoints.$screen-xs) {
      height: 200px;
    }
    @media (min-width: breakpoints.$screen-sm) {
      height: 350px;

      & .logo {
        width: 250px;
      }
    }
    @media (min-width: breakpoints.$screen-md) {
      height: 400px;

      & .logo {
        width: 300px;
      }
    }
    @media (min-width: breakpoints.$screen-lg) {
      height: 450px;

      & .logo {
        width: 350px;
      }
    }
    @media (min-width: breakpoints.$screen-xl) {
      height: 500px;

      & .logo {
        width: 400px;
      }
    }
  }
}

.sectionHeader {
  margin-top: 2em;
  text-align: center;
  font-size: 1.8em;
}

.contentSection {
  max-width: 1200px;
  padding: 64px 24px;
  margin: auto;

  &.fullWidthSm {
    padding: 20px 0;
    @media (min-width: breakpoints.$screen-sm) {
      padding: 64px 24px;
    }
  }

  > h2 {
    text-align: center;
    font-size: 1.8em;
  }
}

.socialMediaSection {
  background-color: #202122;

  & h2 {
    color: #fff;
  }
}

.mutedSection {
  background-color: #e6e6e6;
}

.callToActionButtonContainer {
  text-align: center;
  margin: 16px 0 36px;
}
