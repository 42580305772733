@use "../../../style/abstracts/variables/breakpoints";

.releaseLinks {
  & .link {
    padding: 8px 16px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    font-size: 19px;
    text-decoration: none;
    @media (min-width: breakpoints.$screen-sm) {
      font-size: 22px;
    }
    & svg {
      height: 55px;
      width: 55px;
      margin-right: 10px;
      @media (min-width: breakpoints.$screen-sm) {
        margin-right: 20px;
      }
    }
  }
}
