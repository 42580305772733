@use "../../../style/abstracts/mixins/transition";
@use "../../../style/abstracts/variables/breakpoints";

.listItem {
  border-bottom: 1px solid #eee;
  display: flex;
  align-items: center;
  padding: 0 8px;

  &:not(.compact) {
    align-items: flex-start;
    padding: 8px;
    @media (min-width: breakpoints.$screen-sm) {
      @include transition.transition(box-shadow 225ms cubic-bezier(0, 0, 0.2, 1) 0ms);
      box-shadow:
        0 2px 1px -1px rgba(0, 0, 0, 0.2),
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 1px 3px 0 rgba(0, 0, 0, 0.12);
      margin: 8px;
      width: 350px;
      border-radius: 4px;
      overflow: hidden;
      border: none;
      padding: 0;
      background-color: #fff;
      display: block;
    }
  }
  & img {
    @include transition.transition(opacity 225ms cubic-bezier(0, 0, 0.2, 1) 0ms);
  }
  &:focus-within {
    box-shadow:
      0 2px 4px -1px rgba(0, 0, 0, 0.2),
      0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12);
    & img {
      opacity: 0.8;
    }
  }
}
