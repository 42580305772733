@use "../../../../style/abstracts/all";

.listItemActionButton {
  text-align: right;
  margin: 8px 0;
  &.fullscreen {
    text-align: right;
    margin: 12px;
  }
}
