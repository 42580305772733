@use "../../../../../style/abstracts/variables/colors";

.listItemContentBody {
  color: colors.$article-text-color;
  font-size: 14px;
  & a {
    &:focus {
      outline-color: currentColor;
      outline-offset: 2px;
      outline: 2px solid;
    }
  }
  & ul {
    list-style: none;
    padding: 0;
    margin: 0;

    & li {
      display: inline-block;
      margin-left: 6px;
      font-size: 14px;

      &:not(:first-child) {
        &:before {
          content: " | ";
        }
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }
  &.fullscreen {
    font-size: 16px;

    & ul {
      list-style: none;
      padding: 0;
      margin: 0;

      & li {
        display: inline-block;
        margin-left: 6px;
        font-size: 14px;

        &:not(:first-child) {
          &:before {
            content: " | ";
          }
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
