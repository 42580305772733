$body-background: #f4f4f4;
$default-text-color: #333435;
$article-text-color: #434445;
$primary-text-color: #AF1A12;
$primary-color: #C32A22;
$blue-color: #166E77;
$orange-color: #C36E22;
$green-color: #1A972A;
$yellow-color: #C3A922;
$purple-color: #611C82;
$indigo-color: #3E2486
