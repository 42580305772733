@use "../../style/abstracts/all";

.container {
  max-width: 1200px;
  margin: auto;
  padding: 16px;
  &.blur {
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: filter 225ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  }
}
