.socialMediaLinks {
  text-align: center;

  & a {
    display: inline-block;
    background-color: #555;
    color: #fff;
    font-size: 34px;
    line-height: 68px;
    width: 64px;
    height: 64px;
    text-align: center;
    border-radius: 50%;
    margin: 12px;
    &:hover,
    &:focus {
      color: #fff;
    }

    &.facebook {
      &:hover,
      &:focus {
        background-color: #4267b2;
      }
    }

    &.instagram {
      &:hover,
      &:focus {
        background: #b900b4;
        background: -moz-linear-gradient(left, #b900b4 0%, #f50000 100%);
        background: -webkit-linear-gradient(left, #b900b4 0%, #f50000 100%);
        background: linear-gradient(to right, #b900b4 0%, #f50000 100%);
      }
    }

    &.youtube {
      &:hover,
      &:focus {
        background-color: #ff0000;
      }
    }

    &.vimeo {
      &:hover,
      &:focus {
        background-color: #1ab7ea;
      }
    }

    &.soundcloud {
      &:hover,
      &:focus {
        background-color: #ff5500;
      }
    }

    &.tumblr {
      &:hover,
      &:focus {
        background-color: #051933;
      }
    }

    &.twitter {
      &:hover,
      &:focus {
        background-color: #1da1f2;
      }
    }

    &.pianobook {
      &:hover,
      &:focus {
        background-color: #002b2a;
      }
      & svg {
        height: 34px;
        width: 34px;
        stroke-width: 150;
        position: relative;
        top: 2px;
        left: 2px;
      }
    }

    &.gumroad {
      &:hover,
      &:focus {
        background-color: #ff90e8;
      }
      & svg {
        height: 45px;
        width: 45px;
        position: relative;
        top: 10px;
        left: -1px;
      }
    }

    &.kofi {
      &:hover,
      &:focus {
        background-color: #13c3ff;
      }
      & svg {
        height: 32px;
        width: 49px;
        position: relative;
        left: 2px;
        top: 3px;
      }
    }

    &.rss {
      &:hover,
      &:focus {
        background-color: #f26522;
      }
    }
  }
}
