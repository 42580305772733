@use "../../style/abstracts/all";

.theaterModeHeader {
    display: flex;
    > h2 {
        flex: 1;
    }
    > a {
        height: 28px;
        & .label {
            margin-right: 6px;
            display: inline-block;
            font-size: 1.2em;
            color: inherit;
            vertical-align: middle;
            line-height: 16px;
        }
        & .icon {
            width: 16px;
            display: inline-block;
            vertical-align: middle;
        }
    }
}
