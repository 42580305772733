@use "../../style/abstracts/mixins/border-radius";
@use "../../style/abstracts/mixins/transition";
@use "../../style/abstracts/variables/colors";

.button {
  @include border-radius.border-radius(4px);
  display: inline-block;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  text-transform: uppercase;
  padding: 0;
  background: none;

  & .content {
    @include border-radius.border-radius(4px);
    @include transition.transition(background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms);
    padding: 8px 12px;
    min-width: 64px;
    display: block;
  }

  &.default {
    background-color: colors.$primary-color;
    color: #fff;
    & a {
      color: #fff;
    }

    & .content {
      background-color: rgba(195, 42, 34, 0);
    }

    &:focus,
    &:hover {
      & .content {
        background-color: rgba(0, 0, 0, 0.2);
      }
    }

    &:active {
      & .content {
        background-color: rgba(255, 255, 255, 0.2);
      }
    }
  }

  &.minimal {
    font-size: 0.81rem;
    & .content {
      padding: 13px 8px;
      color: colors.$primary-text-color;
      background-color: transparent;
      & a {
        color: colors.$primary-text-color;
      }
    }

    &:focus,
    &:hover {
      & .content {
        background-color: rgba(195, 42, 34, 0.08);
      }
    }

    &:active {
      & .content {
        background-color: rgba(195, 42, 34, 0.38);
      }
    }
  }
}

*:focus > .button {
  &.default {
    & .content {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
  &.minimal {
    & .content {
      background-color: rgba(195, 42, 34, 0.08);
    }
  }
}
